import { ContentColumn, Headline, Image, ImageColumn, Row } from "./TwoCol_ImageContentSection.styles"

import PropTypes from 'prop-types';
import React from 'react';
import SectionWrapper from '../SectionWrapper'

// import './TwoCol_ImageContentSection.scss';

const TwoCol_ImageContentSection = (props) => (
  <SectionWrapper className="two-col-image-content" backgroundColor={props.backgroundColor} sectionPadding={props.sectionPadding}>
    {props.headline && <Headline>{props.headline}</Headline>}
    <Row imageAlignment={props.imageAlignment}>
      {props.imageAlignment === "left" &&
        // Check if image is set to left side then render
        <ImageColumn>
          <Image
            src={props.imageSrc}
            alt={props.alt}
          />
        </ImageColumn>
      }

      {/* Render the children */}
      <ContentColumn>
        {props.children}
      </ContentColumn>

      {props.imageAlignment === "right" &&
        // Check if image is set to right side then render
        <ImageColumn>
          <Image
            src={props.imageSrc}
            alt={props.alt}
          />
        </ImageColumn>
      }
    </Row>
  </SectionWrapper>
);

TwoCol_ImageContentSection.propTypes = {
  headline: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlignment: PropTypes.oneOf(['left', 'right']),
  backgroundColor: PropTypes.string,
  alt: PropTypes.string,
  sectionPadding: PropTypes.string
};

TwoCol_ImageContentSection.defaultProps = {
  headline: null,
  imageSrc: "https://via.placeholder.com/200x200",
  alt: "",
  imageAlignment: "left",
  backgroundColor: null,
  sectionPadding: null
};

export default TwoCol_ImageContentSection;
