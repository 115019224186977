import styled, {css} from 'styled-components';

import { queries } from "../../../breakpoints"

export const Headline = styled.h2`
    font-size: var(--fontSize-5);
    font-weight: bold;
    text-align: center;
    margin-bottom: 25px;
`;

export const Image = styled.img`
    width: 100%;
    @media(${queries.max_break_lg}){
        max-width:350px;
    }
`;
export const ContentColumn = styled.div` 
    flex:1;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    grid-area: content;
`;
export const ImageColumn = styled(ContentColumn)`
    grid-area: image;
    padding: 20px;
    @media(${queries.min_break_lg}){
        padding: 50px;
    }
`;

export const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    ${props => props.imageAlignment === "left" && css`grid-template-areas: "image content";`}
    ${props => props.imageAlignment === "right" && css`grid-template-areas: "content image";`}
    
    
    @media(${queries.max_break_lg}){
        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-template-areas: "image" "content";
        text-align:center;
    }
`;