import CtaSection from "../components/sections/CtaSection"
import FaqAccordionSection from "../components/sections/FaqAccordionSection"
import IllustrationHeader from "../components/global/IllustrationHeader"
import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection"
import React from "react"
import TwoCol_ImageContentSection from "../components/sections/TwoCol_ImageContentSection"
import { queries } from "../breakpoints"
import styled from 'styled-components';

const AboutUs = ({ data, location }) => {

  const faqs = [
    {
      header: "How does Grubhub Corporate work?",
      description: `<span>A Grubhub Corporate Account makes it easy to provide meal credits for employees and clients. From digital meetings to virtual events, a high quality meal can boost employee morale and delight clients. <a href="/contact-us/">Contact us</a> to learn more.</span>`
    },
    {
      header: "What is a Grubhub line of credit?",
      description: "A Grubhub line of credit is a simple way to provide meals for your team, eliminating the need for credit cards or expense reports. Get one monthly invoice for all food expenses and only pay for the meals that are actually ordered."
    }
  ]

  return (
    <Layout
      location={location}
      title={"About Grubhub Corporate Accounts | Employee Meals Made Easy"}
      description={"A Grubhub Corporate Account can make your hungry team happy. Whether you're remote or in the office, we have flexible corporate ordering options."}
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "learn more",
        pageName: "about corporate accounts",
        brand: "grubhub"
      }}
      faqSeoList={faqs}
    >
      <IllustrationHeader
        headline="About Grubhub Corporate Accounts"
        ctaLink="/get-started/"
        background="radial-gradient(circle, #1D858A 0%, #44C3C0 100%)"
        leftSideIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1628277583/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/4-About%20Us/DESKTOP-AboutUs-LEFT_IMAGE.png"
        altLeftSide="Illustrations of a taco and a pizza slice."
        rightSideIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1652231847/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/About%20Us/DESKTOP-AboutUs-RIGHT_IMAGE.png"
        altRightSide="Illustrations of a salad, a drink, a Grubhub food bag and a cheeseburger."
        mobileIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1652231847/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/About%20Us/MOBILE-AboutUs-HeaderImage.png"
        altMobile="Illustrations of a salad, a drink, a Grubhub food bag and a cheeseburger."
        />
      <TwoCol_ImageContentSection //eslint-disable-line
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797841/Grubhub%20Corporate%20Website/2021/Photography%20Elements/3-Subpage-CircleImages/EXPORTS-IMAGE-Circle-AboutUsPage.png"
        imageAlignment="left"
        sectionPadding="20px 0 0 0"
        alt="Group of three colleagues eating Chinese food with chopsticks."
        >
        <Headline>We make your hungry team happy</Headline>
        <Description>Grubhub Corporate Accounts are part of Grubhub, one of the nation’s leading takeout and delivery marketplaces. We help thousands of companies feed their teams by simplifying the process of ordering meals and paying for those purchases. As a result, we save our clients up to 30 percent on food-related costs*, while making everyone happier and more productive. No matter the occasion Grubhub has you covered with several easy-to-use ordering types. We look forward to making your hungry team happy.</Description>
      </TwoCol_ImageContentSection>
      <QuoteSection
        author="Nichole S"
        position="Managing Director at Telsey Advisory Group"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-TAG.png"
        alt="TAG logo"
        quote="Grubhub has been our go-to corporate meal solution since we started 13 years ago. The offerings have improved Y-O-Y, as well as the ease and convenience Grubhub provides. The customer service is also #1!"
      />
      <CtaSection headline="See how we can help you" backgroundColor="#f6f6f6"/>
      <FaqAccordionSection faqs={faqs} backgroundColor="#ffffff" />
    </Layout>
  )
}

export default AboutUs


const Headline = styled.h2`
  font-weight: bold;
  font-size: var(--fontSize-5);
  text-align:center;
  align-self: center;
  @media (${queries.min_break_lg}){
    align-self: flex-start;
    text-align:left;
    padding-top: 50px;
  }
`;

const Description = styled.p`
  font-size: var(--fontSize-2);
`;
